<form nz-form [formGroup]="alterarSenhaForm" nzLayout="vertical">
  <d nz-row nzGutter="24">
    <div nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-label nzFor="senhaAtual" nzRequired>Senha</nz-form-label>
        <nz-form-control nzErrorTip="A Senha não é valida">
          <nz-input-group [nzSuffix]="exibirSenhaIcon1">
            <input
              [type]="mostrarSenha ? 'text' : 'password'"
              nz-input
              senha
              formControlName="senhaAtual"
              id="senhaAtual"
              placeholder="Senha"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-label nzFor="novaSenha" nzRequired>Nova Senha</nz-form-label>
        <nz-form-control [nzErrorTip]="errorTpl">
          <nz-input-group [nzSuffix]="exibirSenhaIcon2">
            <input
              [type]="mostrarConfirmacaoDeSenha ? 'text' : 'password'"
              nz-input
              senha
              formControlName="novaSenha"
              id="novaSenha"
              placeholder="Confirme a Senha"
            />
            <ng-template #errorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                Por favor, confirme sua senha.
              </ng-container>
              <ng-container *ngIf="control.hasError('confirm')">
                As senhas digitadas não coincidem.
              </ng-container>
            </ng-template>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-label nzFor="novaSenha" nzRequired>Nova Senha</nz-form-label>
        <nz-form-control [nzErrorTip]="errorTpl">
          <nz-input-group [nzSuffix]="exibirSenhaIcon3">
            <input
              [type]="mostrarConfirmacaoDeSenha2 == true ? 'text' : 'password'"
              nz-input
              senha
              formControlName="confirmacaoNovaSenha"
              id="confirmacaoNovaSenha"
              placeholder="Confirme a Senha"
            />
            <ng-template #errorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                Por favor, confirme sua senha.
              </ng-container>
              <ng-container *ngIf="control.hasError('confirm')">
                As senhas digitadas não coincidem.
              </ng-container>
            </ng-template>
          </nz-input-group>
        </nz-form-control>
        </nz-form-item>
    </div>
  </d>
</form>
<div *nzModalFooter>
  <button nz-button (click)="destroyModal()">Cancelar</button>
  <button
    nz-button
    nzType="primary"
    (click)="alterarSenha()"
    [disabled]="alterarSenhaForm.invalid"
    [nzLoading]="saving"
  >
    Salvar
  </button>
</div>

<ng-template #exibirSenhaIcon1>
  <i
    nz-icon
    [nzType]="mostrarSenha ? 'eye-invisible' : 'eye'"
    (click)="mostraSenha()"
    style="cursor: pointer"
  ></i>
</ng-template>
<ng-template #exibirSenhaIcon2>
  <i
    nz-icon
    [nzType]="mostrarConfirmacaoSenha ? 'eye-invisible' : 'eye'"
    (click)="mostraConfirmacaoDeSenha()"
    style="cursor: pointer"
  ></i>
</ng-template>
<ng-template #exibirSenhaIcon3>
  <i
    nz-icon
    [nzType]="mostrarConfirmacaoSenha2 ? 'eye-invisible' : 'eye'"
    (click)="mostraConfirmacaoDeSenha2()"
    style="cursor: pointer"
  ></i>
</ng-template>
