import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from "./layouts/full-layout/full-layout.component";
import { CommonLayoutComponent } from "./layouts/common-layout/common-layout.component";

import { FullLayout_ROUTES } from "./shared/routes/full-layout.routes";
import { CommonLayout_ROUTES } from "./shared/routes/common-layout.routes";
import { AuthGuard } from './authentication/auth-guard.service';

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: '/pagina-inicial',
        pathMatch: 'full',
        canActivate: [AuthGuard]
    },
    {
        path: '',
        component: CommonLayoutComponent,
        children: CommonLayout_ROUTES
    },
    {
        path: '',
        component: FullLayoutComponent,
        children: FullLayout_ROUTES
    },
    { path: 'pagina-inicial', loadChildren: () => import('./default/default.module').then(m => m.DefaultModule) },
    { path: 'atendimentos', loadChildren: () => import('./atendimentos/atendimentos.module').then(m => m.AtendimentosModule) },
    { path: 'atualizacao-cadastral', loadChildren: () => import('./atualizacao-cadastral/atualizacao-cadastral.module').then(m => m.AtualizacaoCadastralModule) },
    { path: 'profissionais', loadChildren: () => import('./profissionais/profissionais.module').then(m => m.ProfissionaisModule) },
    { path: 'nova-consulta', loadChildren: () => import('./nova-consulta/nova-consulta.module').then(m => m.NovaConsultaModule) },
    { path: 'sala-espera', loadChildren: () => import('./sala-espera/sala-espera.module').then(m => m.SalaEsperaModule) },
    { path: 'sala-espera-externa', loadChildren: () => import('./sala-espera-externa/sala-espera-externa.module').then(m => m.SalaEsperaExternaModule) },
    { path: 'valida', loadChildren: () => import('./valida/valida.module').then(m => m.ValidaModule) },
    { path: 'share-consulta', loadChildren: () => import('./share-consulta/share-consulta.module').then(m => m.ShareConsultaModule) },
    { path: 'avaliacao-atendimento', loadChildren: () => import('./avaliacao-atendimento/avaliacao-atendimento.module').then(m => m.AvaliacaoAtendimentoModule) },
    { path: 'editar-perfil', loadChildren: () => import('./pacientes/perfil-editar-pacientes/perfil-editar-pacientes.module').then(m => m.PerfilEditarPacientesModule) },
    { path: 'pets', loadChildren: () => import('./pets/pets.module').then(m => m.PetsModule) },
    { path: 'especies', loadChildren: () => import('./especies/especies.module').then(m => m.EspeciesModule) },
    { path: 'administradores', loadChildren: () => import('./administrador/administrador-routing.module').then(m => m.AdministradorRoutingModule) },
    { path: 'disponibilidade-retorno', loadChildren: () => import('./disponibilidade-retorno/disponibilidade-retorno.module').then(m => m.DisponibilidadeRetornoModule) },
    { path: 'especialidades', loadChildren: () => import('./especialidades/especialidades.module').then(m => m.EspecialidadesModule) },
    { path: 'contratos-padrao', loadChildren: () => import('./contratos-padrao/contratos-padrao.module').then(m => m.ContratosPadraoModule) },
    { path: 'empresas', loadChildren: () => import('./empresas/empresas.module').then(m => m.EmpresasModule) },
    { path: 'assinaturas', loadChildren: () => import('./assinaturas/assinaturas.module').then(m => m.AssinaturasModule) },
    { path: 'cupons', loadChildren: () => import('./cupons/cupons.module').then(m => m.CuponsModule) },
    { path: 'grupos-acesso', loadChildren: () => import('./grupo-acesso/grupo-acesso.module').then(m => m.GrupoAcessoModule) },
    { path: 'tutores-search', loadChildren: () => import('./pacientes/admin-editar-pacientes/pacientes-search.module').then(m => m.PacientesSearchModule) },
    { path: 'pagina-inicial-administrador', loadChildren: () => import('./pagina-inicial/pagina-inicial.module').then(m => m.PaginaInicialModule) },
    { path: 'mensagem-espera', loadChildren: () => import('./mensagem-espera/mensagem-espera.module').then(m => m.MensagemEsperaModule) },
    { path: 'vendedores', loadChildren: () => import('./vendedores/vendedores.module').then(m => m.VendedoresModule) }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            preloadingStrategy: PreloadAllModules,
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'enabled',
            useHash: false
        })
    ],
    exports: [
        RouterModule
    ]
})

export class AppRoutingModule {
}
