import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EventService } from 'src/app/shared/services/event.service';
import { ThemeConstantService } from 'src/app/shared/services/theme-constant.service';
import {
  AtendimentosClient,
  AtendimentosProfissionaisTotalDiaResult,
  FilaAtendimentosClient,
} from 'web-api-client';

@Component({
  selector: 'app-total-atendimentos-profissional-dia',
  templateUrl: './total-atendimentos-profissional-dia.component.html',
  styleUrls: ['./total-atendimentos-profissional-dia.component.scss'],
})
export class TotalAtendimentosProfissionalDiaComponent {
  pacienteAguardandoAtendimento: number = 0;
  pacienteEmAtendimento: number = 0;
  atendenteAguardandoAtendimento: number = 0;
  atendenteEmAtendimento: number = 0;
  medicoAguardandoAtendimento: number = 0;
  medicoEmAtendimento: number = 0;
  atendimentosProfissionaisTotalDia: AtendimentosProfissionaisTotalDiaResult;
  carregandoAtendimentosProfissionaisTotalDia: boolean;

  exibirDados: boolean;

  private isMenuFoldedChangesSubscription: Subscription;
  private situacaoAtendimentoChangeEventSubscription: Subscription;
  private alteracaoNaFilaChangeSub: Subscription;

  constructor(
    private eventService: EventService,
    private atendimentosClient: AtendimentosClient,
    private filaAtendimentosClient: FilaAtendimentosClient,
    private themeConstantService: ThemeConstantService
  ) { }

  ngOnInit(): void {
    this.obteStatusFila(null);
    this.obterQuantidadeConsultas();

    this.isMenuFoldedChangesSubscription = this.themeConstantService.isMenuFoldedChanges.subscribe(
      (r) => {
        this.exibirDados = !r;
      }
    );

    this.alteracaoNaFilaChangeSub = this.eventService.AlteracaoNaFilaChangeEvent.subscribe(
      (s) => {
        console.log(s);
        console.log("ESCUTOU O EVENTO");
        this.obteStatusFila(s);
        this.obterQuantidadeConsultas();
      }
    );
  }

  ngOnDestroy() {
    this.isMenuFoldedChangesSubscription &&
      this.isMenuFoldedChangesSubscription.unsubscribe();

    this.situacaoAtendimentoChangeEventSubscription &&
      this.situacaoAtendimentoChangeEventSubscription.unsubscribe();

    this.alteracaoNaFilaChangeSub &&
      this.alteracaoNaFilaChangeSub.unsubscribe();
  }

  private obterQuantidadeConsultas() {
    this.carregandoAtendimentosProfissionaisTotalDia = true;
    this.atendimentosClient
      .obterQuantidadeConsultasDia()
      .pipe(
        finalize(() => {
          this.carregandoAtendimentosProfissionaisTotalDia = false;
        })
      )
      .subscribe((r) => {
        this.atendimentosProfissionaisTotalDia = r;
      });
  }

  private obteStatusFila(s) {
    this.carregandoAtendimentosProfissionaisTotalDia = true;

    if (!s) {
      this.filaAtendimentosClient
        .obterStatus()
        .pipe(
          finalize(() => {
            this.carregandoAtendimentosProfissionaisTotalDia = false;
          })
        )
        .subscribe((r) => {
          this.pacienteAguardandoAtendimento = r.pacienteEmEspera;
          this.pacienteEmAtendimento = r.pacienteEmAtendimento;

          this.atendenteAguardandoAtendimento = r.atendenteEmEspera;
          this.atendenteEmAtendimento = r.atendenteEmAtendimento;

          this.medicoAguardandoAtendimento = r.medicoEmEspera;
          this.medicoEmAtendimento = r.medicoEmAtendimento;
        });
    }
    else {
      console.log(s);
      this.pacienteAguardandoAtendimento = s.PacienteEmEspera;
      this.pacienteEmAtendimento = s.PacienteEmAtendimento;

      this.atendenteAguardandoAtendimento = s.AtendenteEmEspera;
      this.atendenteEmAtendimento = s.AtendenteEmAtendimento;

      this.medicoAguardandoAtendimento = s.MedicoEmEspera;
      this.medicoEmAtendimento = s.MedicoEmAtendimento;
    }

  }

}
