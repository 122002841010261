
<nz-card *ngIf="exibirDados && atendimentosProfissionaisTotalDia" nzTitle="Fila Atendimentos"
[nzLoading]="carregandoAtendimentosProfissionaisTotalDia" class="rounded-0 border-right-0">
    <!--PACIENTES -->
    <b>Pacientes:</b>

    <nz-row>
      <nz-col nzSpan="17">
        Espera:
      </nz-col>
      <nz-col nzSpan="7" class="text-center">{{ pacienteAguardandoAtendimento }}</nz-col>

      <nz-col nzSpan="17">
        <p>Atendimento:</p>
      </nz-col>
      <nz-col nzSpan="7" class="text-center">{{ pacienteEmAtendimento }}</nz-col>
    </nz-row>

    <!--Atendentes -->
    <b>Atendentes:</b>

    <nz-row>
      <nz-col nzSpan="17">
        Disponivel:
      </nz-col>
      <nz-col nzSpan="7" class="text-center">{{ atendenteAguardandoAtendimento}}</nz-col>

      <nz-col nzSpan="17">
        <p>Atendimento:</p>
      </nz-col>
      <nz-col nzSpan="7" class="text-center">{{ atendenteEmAtendimento }}</nz-col>
    </nz-row>

    <!--Medicos -->
    <b>Medicos:</b>

    <nz-row>
      <nz-col nzSpan="17">
        Diponivel:
      </nz-col>
      <nz-col nzSpan="7" class="text-center">{{ medicoAguardandoAtendimento }}</nz-col>

      <nz-col nzSpan="17">
        <p>Atendimento:</p>
      </nz-col>
      <nz-col nzSpan="7" class="text-center">{{ medicoEmAtendimento }}</nz-col>
    </nz-row>

    <br>

    <nz-row nzGutter="24">
      <nz-col nzSpan="17">
        <b>Realizados Hoje</b>
      </nz-col>
      <nz-col nzSpan="7" class="text-center">{{ atendimentosProfissionaisTotalDia.quantidadeConsultas }}</nz-col>
    </nz-row>

    <nz-row nzGutter="24" class="mt-2">
      <nz-col nzSpan="17">
        <b>Recusados Hoje</b>
      </nz-col>
      <nz-col nzSpan="7" class="text-center">{{ atendimentosProfissionaisTotalDia.quantidadeRecusados }}</nz-col>
    </nz-row>

    <nz-row nzGutter="24" class="mt-2">
      <nz-col nzSpan="17">
        <b>Perdidos Hoje</b>
      </nz-col>
      <nz-col nzSpan="7" class="text-center">{{ atendimentosProfissionaisTotalDia.quantidadePerdidos }}</nz-col>
    </nz-row>

    <br>
    <br>
</nz-card>
