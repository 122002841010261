import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { finalize } from 'rxjs/operators';
import {
  AtendimentoJustificarRecusaCommand,
  AtendimentosClient,
  ProfissionaisClient,
  SituacaoProfissionalVm,
} from 'web-api-client';
import { AuthenticationService } from '../../services/authentication.service';
import { EventService } from '../../services/event.service';
import { ConfiguracaoService } from '../../services/configuracao.service';
@Component({
  selector: 'app-atendimento-encontrado-modal.component',
  templateUrl: './atendimento-encontrado-modal.component.html',
})
export class AtendimentoEncontradoModalComponent implements OnInit {
  @Input() atendimento: PacienteNaFilaModel;

  @ViewChild('justificarRecusaTplModalContent', { static: true })
  justificarRecusaTplModalContent: TemplateRef<any>;

  @ViewChild('justificarRecusaTplModalFooter', { static: true })
  justificarRecusaTplModalFooter: TemplateRef<any>;

  tempoMaximoAceite: number = 90; // em segundos
  tempoRestante: number;
  contagemRegressivaAtiva: boolean;
  contagemRegressivaIntervalTimeout: NodeJS.Timeout;

  private audioAlerta: HTMLAudioElement;
  private readonly lastDocumentTitle: string;

  aceitando: boolean;
  recusando: boolean;

  private situacaoAposRecusar: SituacaoProfissionalVm;
  justificativaRecusaFormControl = new FormControl(null, Validators.required);

  constructor(
    private router: Router,
    private modal: NzModalRef,
    private titleService: Title,
    private modalService: NzModalService,
    private eventService: EventService,
    private atendimentoClient: AtendimentosClient,
    private profissionaisClient: ProfissionaisClient,
    private authenticationService: AuthenticationService,
    private notification: NzNotificationService,
    private configuracao: ConfiguracaoService
  ) {
    this.lastDocumentTitle = this.titleService.getTitle();
  }

  ngOnInit(): void {
    this.tempoMaximoAceite = this.configuracao.getPrazoMaximoAssumirAtendimento();
    this.setDocumentTitle();
    this.playAudioAlerta();
    this.initContagemRegressiva();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.modal.updateConfig({
        nzTitle: 'Atendimento encontrado',
        nzMaskClosable: false,
        nzClosable: false,
      });
    });
  }

  ngOnDestroy(): void {
    this.pararContagemRegressiva();
    this.destroyAudioAlerta();
    this.restoreLastDocumentTitle();
  }

  private setDocumentTitle() {
    this.titleService.setTitle('(1) ' + this.lastDocumentTitle);
  }

  private restoreLastDocumentTitle() {
    this.titleService.setTitle(this.lastDocumentTitle);
  }

  private playAudioAlerta() {
    this.audioAlerta = new Audio();
    this.audioAlerta.src =
      '../../../assets/audio/alert-atendimento-encontrado.mp3';
    this.audioAlerta.load();
    this.audioAlerta.play();
  }

  private playAudioLogout() {
    this.audioAlerta = new Audio();
    this.audioAlerta.src =
      '../../../assets/audio/alert-logout-off.mp3';
    this.audioAlerta.load();
    this.audioAlerta.play();
  }


  private destroyAudioAlerta() {
    if (this.audioAlerta) {
      this.audioAlerta.pause();
      this.audioAlerta = null;
    }
  }

  private initContagemRegressiva() {
    this.tempoRestante = this.tempoMaximoAceite;
    this.contagemRegressivaAtiva = true;
    this.eventService.ExibindoModalAtendimentoEncontradoEvent.emit(true);
    this.contagemRegressivaIntervalTimeout = setInterval(() => {
      if (this.tempoRestante <= 0) {
        this.pararContagemRegressiva();
        this.recusarSemJustificar();
      }
      this.tempoRestante -= 1;
    }, 1000);
  }

  private pararContagemRegressiva() {
    this.eventService.ExibindoModalAtendimentoEncontradoEvent.emit(false);
    clearInterval(this.contagemRegressivaIntervalTimeout);
    this.contagemRegressivaAtiva = false;
  }

  aceitar() {
    this.pararContagemRegressiva();
    this.aceitando = true;
    this.eventService.AceitandoRecusandoAtendimentoEvent.emit(true);
    this.atendimentoClient
      .assumir(this.atendimento.Id)
      .pipe(
        finalize(() => {
          this.aceitando = false;
          this.eventService.AceitandoRecusandoAtendimentoEvent.emit(false);
          this.atualizarSituacaoAtendimentoProfissional();
        })
      )
      .subscribe(
        (res) => {
          this.router.navigate(['atendimentos', this.atendimento.Id, 'sala']);
          this.fecharModal();
        },
        (err) => {
          this.fecharModal();
          this.notification.error(
            'Erro',
            'Ocorreu um erro ao executar a operação'
          );
        }
      );
  }

  recusar() {
    this.pararContagemRegressiva();
    this.recusando = true;
    this.eventService.AceitandoRecusandoAtendimentoEvent.emit(true);
    this.atendimentoClient
      .recusar(this.atendimento.Id)
      .pipe(
        finalize(() => {
          this.recusando = false;
          this.eventService.AceitandoRecusandoAtendimentoEvent.emit(false);
          this.atualizarSituacaoAtendimentoProfissional();
          this.playAudioLogout();
        })
      )
      .subscribe(
        (res) => {
          this.situacaoAposRecusar = res;
          this.exibirModalJustificarRecusa();
        },
        (err) => {
          this.notification.error(
            'Ooops...',
            'Ocorreu um erro ao executar a operação'
          );
        }
      );
  }

  private recusarSemJustificar() {
    this.pararContagemRegressiva();
    this.recusando = true;
    this.atendimentoClient
      .recusar(this.atendimento.Id)
      .pipe(
        finalize(() => {
          this.recusando = false;
          this.atualizarSituacaoAtendimentoProfissional();
        })
      )
      .subscribe((res) => {
        this.exibirNotificacaoAposRecusa(res);
      });
    this.fecharModal();
  }

  private exibirModalJustificarRecusa(): void {
    this.modalService.create({
      nzTitle: 'Qual o motivo da recusa?',
      nzContent: this.justificarRecusaTplModalContent,
      nzFooter: this.justificarRecusaTplModalFooter,
      nzMaskClosable: false,
      nzClosable: false,
    });
  }

  justificarRecusa(justificarRecusaModalRef: NzModalRef) {
    let req = new AtendimentoJustificarRecusaCommand({
      atendimentoId: this.atendimento.Id,
      justificativa: this.justificativaRecusaFormControl.value,
      atendimentoProfissionalRecusaId: this.situacaoAposRecusar
        .atendimentoProfissionalRecusaId,
    });

    this.atendimentoClient.justificarRecusa(req).subscribe((r) => {
      this.atualizarSituacaoAtendimentoProfissional();
    });

    this.exibirNotificacaoAposRecusa(this.situacaoAposRecusar);
    justificarRecusaModalRef.destroy();
    this.fecharModal();
  }

  nzProgressFormat(percent: number): string {
    return percent + '';
  }

  private exibirNotificacaoAposRecusa(
    situacaoProfissional: SituacaoProfissionalVm
  ) {
    if (situacaoProfissional.quantidadeTentativasChamadasProfisisonal < 3) {
      this.notification.info(
        'Atendimento Recusado',
        'O atendimento foi recusado e você foi retirado da fila de atendimento. Caso deseje retomar os atendimentos, basta ficar online novamente',
        { nzDuration: 0 }
      );
    } else {
      this.notification.warning(
        'Atendimento Recusado',
        'O atendimento foi recusado várias vezes e sua situação foi marcada como Offline. Você pode realizar novos atendimentos quando ficar online novamente',
        { nzDuration: 0 }
      );
    }
  }

  private atualizarSituacaoAtendimentoProfissional() {
    const usuarioLogado = this.authenticationService.obterUsuarioLogado;
    this.profissionaisClient
      .obterSituacaoAtendimento(usuarioLogado.profissionalId)
      .subscribe((r) => {
        this.eventService.SituacaoAtendimentoChangeEvent.emit(r);
      });
  }

  fecharModal() {
    this.modal.destroy();
  }
}
class PacienteNaFilaModel {
  public Id: number;
  public PacienteNome: string;
  public TituloAssinatura: string;
  public Cupom: string;
  public QuantidadeAtendimentos: number;
}
