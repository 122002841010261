import { Routes } from '@angular/router';

export const CommonLayout_ROUTES: Routes = [
  {
    path: 'pagina-inicial',
    loadChildren: () => import('../../default/default.module').then(m => m.DefaultModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'atendimentos',
    loadChildren: () => import('../../atendimentos/atendimentos.module').then(m => m.AtendimentosModule),
  },
  {
    path: 'atualizacao-cadastral',
    loadChildren: () => import('../../atualizacao-cadastral/atualizacao-cadastral.module').then(m => m.AtualizacaoCadastralModule),
  },
  {
    path: 'profissionais',
    loadChildren: () => import('../../profissionais/profissionais.module').then(m => m.ProfissionaisModule)
  },
  {
    path: 'tutores-search',
    loadChildren: () => import('../../pacientes/admin-editar-pacientes/pacientes-search.module').then(m => m.PacientesSearchModule)
  },
  {
    path: 'especies',
    loadChildren: () => import('../../especies/especies.module').then(m => m.EspeciesModule)
  },
  {
    path: 'share-consulta',
    loadChildren: () => import('../../share-consulta/share-consulta.module').then(m => m.ShareConsultaModule)
  },
  {
    path: 'nova-consulta',
    loadChildren: () => import('../../nova-consulta/nova-consulta.module').then(m => m.NovaConsultaModule)
  },
  {
    path: 'sala-espera',
    loadChildren: () => import('../../sala-espera/sala-espera.module').then(m => m.SalaEsperaModule)
  },
  {
    path: 'avaliacao-atendimento',
    loadChildren: () => import('../../avaliacao-atendimento/avaliacao-atendimento.module').then(m => m.AvaliacaoAtendimentoModule)
  },
  {
    path: 'editar-perfil',
    loadChildren: () => import('../../pacientes/perfil-editar-pacientes/perfil-editar-pacientes.module').then(m => m.PerfilEditarPacientesModule)
  },
  {
    path: 'pets',
    loadChildren: () => import('../../pets/pets.module').then(m => m.PetsModule)
  },
  {
    path: 'disponibilidade-retorno',
    loadChildren: () => import('../../disponibilidade-retorno/disponibilidade-retorno.module').then(m => m.DisponibilidadeRetornoModule)
  },
  {
    path: 'disputa',
    loadChildren: () => import('../../disputa/disputa.module').then(m => m.DisputaModule)
  },
  {
    path: 'administradores',
    loadChildren: () => import('../../administrador/administrador.module').then(m => m.AdministradorModule)
  },
  {
    path: 'especialidades',
    loadChildren: () => import('../../especialidades/especialidades.module').then(m => m.EspecialidadesModule)
  },
  {
    path: 'empresas',
    loadChildren: () => import('../../empresas/empresas.module').then(m => m.EmpresasModule),
  },
  {
    path: 'dados-empresa',
    loadChildren: () => import('../../empresas/empresas.module').then(m => m.EmpresasModule),
  },
  {
    path: 'assinaturas',
    loadChildren: () => import('../../assinaturas/assinaturas.module').then(m => m.AssinaturasModule)
  },
  {
    path: 'contratos-padrao',
    loadChildren: () => import('../../contratos-padrao/contratos-padrao.module').then(m => m.ContratosPadraoModule)
  },
  {
    path: 'admin-assinaturas',
    loadChildren: () => import('../../admin-assinaturas/admin-assinaturas.module').then(m => m.AdminAssinaturasModule)
  },
  {
    path: 'cupons',
    loadChildren: () => import('../../cupons/cupons.module').then(m => m.CuponsModule)
  },
  {
    path: 'grupos-acesso',
    loadChildren: () => import('../../grupo-acesso/grupo-acesso.module').then(m => m.GrupoAcessoModule)
  },
  {
    path: 'pagina-inicial-administrador',
    loadChildren: () => import('../../pagina-inicial/pagina-inicial.module').then(m => m.PaginaInicialModule)
  },
  {
    path: 'mensagem-espera',
    loadChildren: () => import('../../mensagem-espera/mensagem-espera.module').then(m => m.MensagemEsperaModule)
  },
  {
    path: 'vendedores',
    loadChildren: () => import('../../vendedores/vendedores.module').then(m => m.VendedoresModule)
  },
  {
    path: 'vendas',
    loadChildren: () => import('../../vendas/vendas.module').then(m => m.VendasModule)
  }
];
