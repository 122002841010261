import { DecimalPipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { interval, Subscription } from 'rxjs';
import { EventService } from 'src/app/shared/services/event.service';

import { AtendimentosClient, AtendimentoVM } from 'web-api-client';

interface TimeSpan {
  hours: number;
  minutes: number;
  seconds: number;
}

@Component({
  selector: 'app-temporizador-atendimento',
  templateUrl: './temporizador-atendimento.component.html',
})
export class TemporizadorAtendimentoComponent implements OnInit {
  @Input() atendimentoId: number;

  atendimento: AtendimentoVM;

  private detectChangesSubscription: Subscription;
  private situacaoAtendimentoChangeEventSubscription: Subscription;

  private serverTime: moment.Moment;

  constructor(
    private decimalPipe: DecimalPipe,
    private eventService: EventService,
    private changeDetector: ChangeDetectorRef,
    private atendimentosClient: AtendimentosClient
  ) {}

  ngOnInit() {
    let situacaoProfissional = this.eventService.SituacaoAtendimentoChangeEvent.value;

    if (situacaoProfissional && situacaoProfissional.atendimentoId) {
      this.initTimer(situacaoProfissional.atendimentoId);
    }

    this.situacaoAtendimentoChangeEventSubscription = this.eventService.SituacaoAtendimentoChangeEvent.subscribe(
      (r) => {
        if (r.atendimentoId) {
          this.initTimer(r.atendimentoId);
        }
      }
    );
  }

  ngOnDestroy() {
    this.timerDetectChangesUnsubscribe();
    this.situacaoAtendimentoChangeEventSubscription &&
      this.situacaoAtendimentoChangeEventSubscription.unsubscribe();
  }

  private initTimer(atendimentoId: number): void {
    this.atendimentosClient.obterPorId(atendimentoId).subscribe((r) => {
      this.atendimento = r;
      if (!this.atendimento.dataTerminoAtendimento) {
        this.timerDetectChangesSubscribe();
      } else {
        this.timerDetectChangesUnsubscribe();
      }
    });
  }

  private timerDetectChangesSubscribe() {
    this.timerDetectChangesUnsubscribe();

    this.serverTime = moment(this.atendimento.strDataAtualServidor).utcOffset(0, true);

    this.detectChangesSubscription = interval(1000).subscribe(() => {
      this.changeDetector.detectChanges();
      this.serverTime = this.serverTime.add(1000, 'milliseconds');
    });
  }

  private timerDetectChangesUnsubscribe() {
    this.detectChangesSubscription &&
      this.detectChangesSubscription.unsubscribe();
  }

  getElapsedTime(startDate: Date): TimeSpan {
    // https://stackoverflow.com/a/60587779

    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    if (this.serverTime) {
      startDate = moment(startDate).utcOffset(0, false).toDate();

      let totalSeconds = Math.floor(
        (this.serverTime.valueOf() - startDate.getTime()) / 1000
      );

      if (totalSeconds >= 3600) {
        hours = Math.floor(totalSeconds / 3600);
        totalSeconds -= 3600 * hours;
      }

      if (totalSeconds >= 60) {
        minutes = Math.floor(totalSeconds / 60);
        totalSeconds -= 60 * minutes;
      }

      seconds = totalSeconds;
    }

    return {
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    };
  }

  formatarTempoDecorrido(timeSpan: TimeSpan): string {
    let h = this.decimalPipe.transform(timeSpan.hours, '2.0');
    let m = this.decimalPipe.transform(timeSpan.minutes, '2.0');
    let s = this.decimalPipe.transform(timeSpan.seconds, '2.0');
    return `${h}:${m}:${s}`;
  }

  styleTempoDecorrido(timeSpan: TimeSpan): any {
    if (timeSpan.hours > 0 || timeSpan.minutes >= 15) {
      return { color: '#de4436' };
    }
    return {};
  }

  // para saber a duração final do atendimento caso o mesmo já tenha se encerrado
  get duracaoAtendimento(): string {
    // https://stackoverflow.com/a/18624295
    let ms = moment(this.atendimento.dataTerminoAtendimento).diff(
      moment(this.atendimento.dataInicioAtendimento)
    );
    let d = moment.duration(ms);
    return Math.floor(d.asHours()) + moment.utc(ms).format('[h] mm[m] ss[s]');
  }
}
